/**
 * Otzy API Documentation
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum AppWebSocketPhysicalPaymentRequestReceiverUpdateDtoType {
    WEB_SOCKET_PHYSICAL_PAYMENT_REQUEST_RECEIVER_UPDATE = '[WebSocket] Physical Payment Request Receiver Update'
}

