/**
 * Otzy API Documentation
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum QrCodePaymentRequestStatus {
    CREATED = 'CREATED',
    SCANNED = 'SCANNED',
    CONFIRMED = 'CONFIRMED',
    PARTIALLY_CONFIRMED = 'PARTIALLY_CONFIRMED',
    FAILED = 'FAILED',
    ABORTED = 'ABORTED',
    TIMEOUT = 'TIMEOUT'
}

