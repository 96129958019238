/**
 * Otzy API Documentation
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyOpenId {
    ALREADY_IN_USE = 'openid-already-in-use',
    USER_NOT_FOUND = 'openid-user-not-found',
    PROVIDER_NOT_FOUND = 'openid-provider-not-found',
    INVALID_TOKEN_USE = 'openid-invalid-token-use',
    LOGIN_NOT_FOUND = 'openid-login-not-found',
    INVALID_STATE = 'openid-invalid-state',
    INVALID_CODE_VERIFIER = 'openid-invalid-code-verifier',
    INVALID_NONCE = 'openid-invalid-nonce',
    PROVIDER_ERROR = 'openid-provider-error'
}

