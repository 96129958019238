/**
 * Otzy API Documentation
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyVouchers {
    INVALID_COIN = 'voucher-invalid-coin',
    INVALID_FUND = 'voucher-invalid-fund',
    NOT_FOUND = 'voucher-not-found'
}

