/**
 * Otzy API Documentation
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum IdpOauth2AuthorizeAction {
    REGISTER = 'register',
    SIGN_IN = 'sign-in'
}

