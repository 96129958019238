/**
 * Otzy API Documentation
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyEconomies {
    NOT_FOUND = 'economy-not-found',
    INVITATION_ONLY = 'economy-invitation-only',
    INVALID = 'economy-invalid',
    NOT_ACTIVE = 'economy-not-active'
}

