/**
 * Otzy API Documentation
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum PhysicalPaymentMethod {
    BARCODE_FORMAT_EAN_13 = 'barcode-format-ean-13'
}

