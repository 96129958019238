/**
 * Otzy API Documentation
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyEconomyHoldingAccount {
    ECONOMY_HOLDING_ACCOUNT_NOT_FOUND = 'economy-holding-account-not-found'
}

