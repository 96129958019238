/**
 * Otzy API Documentation
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyCoins {
    NOT_FOUND = 'coin-not-found',
    HAS_BALANCE = 'coin-has-balance',
    NO_HOLDING_ACCOUNT = 'coin-no-holding-account',
    HAS_HOLDING_ACCOUNT = 'coin-has-holding-account',
    NOT_WHITELISTED = 'coin-not-whitelisted'
}

