/**
 * Otzy API Documentation
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum IdpUsersApiMethod {
    SIGN_UP = 'sign_up',
    SIGN_UP_ANONYMOUSLY = 'sign_up_anonymously',
    SIGN_UP_WITH_OPENID = 'sign_up_with_openid',
    SEND_OOB_CODE = 'send_oob_code',
    SIGN_IN_WITH_EMAIL = 'sign_in_with_email',
    SIGN_IN_WITH_PASSWORD = 'sign_in_with_password',
    SIGN_IN_WITH_OPENID = 'sign_in_with_openid',
    SIGN_IN_WITH_SOFT_KEY = 'sign_in_with_soft_key',
    RESET_PASSWORD = 'reset_password'
}

