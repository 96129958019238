import { effect, inject, Injectable, signal } from '@angular/core';
import { ThemeMainColorType, ThemingService } from '@icp/angular/theming';
import { Oauth2LoginDto } from '@icp/interfaces';

function getInitialLoginConfig(): Oauth2LoginDto | null {
    const stored = localStorage.getItem('oauthLogin');
    const result = stored ? (JSON.parse(stored) as Oauth2LoginDto) : null;
    if (result) {
        const isExpired = new Date(result.expiresAt) < new Date();
        if (isExpired) {
            localStorage.removeItem('oauthLogin');
            return null;
        }
    }
    return result;
}

@Injectable({ providedIn: 'root' })
export class AuthorizeStorageService {
    private oauthLogin = signal<Oauth2LoginDto | null>(getInitialLoginConfig());
    loginConfig = this.oauthLogin.asReadonly();
    private themingService = inject(ThemingService);
    hasRedirectedToRegister = false;

    constructor() {
        effect(
            () => {
                const config = this.oauthLogin();
                if (config?.client?.colorPrimary) {
                    this.themingService.setupTheme({
                        [ThemeMainColorType.PRIMARY]: config.client.colorPrimary,
                        [ThemeMainColorType.ACCENT]: config.client.colorPrimary,
                    });
                } else {
                    this.themingService.setupTheme(null);
                }
            },
            { allowSignalWrites: true },
        );
    }

    setLoginConfig(config: Oauth2LoginDto) {
        localStorage.setItem('oauthLogin', JSON.stringify(config));
        this.oauthLogin.set(config);
    }

    resetLoginConfig() {
        localStorage.removeItem('oauthLogin');
        // Don't set the signal to null to avoid flicker
    }
}
