/**
 * Otzy API Documentation
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyFundraisers {
    NOT_FOUND = 'fundraiser-not-found',
    STILL_HAS_FUNDS = 'fundraiser-still-has-funds'
}

