/**
 * Otzy API Documentation
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyWallet {
    INVALID = 'wallet-invalid',
    COIN_NOT_FOUND = 'wallet-coin-not-found',
    INSUFFICIENT_BALANCE = 'wallet-insufficient-balance',
    ARCHIVED = 'wallet-archived',
    NOT_ACTIVE = 'wallet-not-active',
    PART_NOT_FOUND = 'wallet-part-not-found',
    PART_INSUFFICIENT_BALANCE = 'wallet-part-insufficient-balance'
}

